import React, { Fragment } from 'react'
import styled from 'styled-components'
// import { darken } from 'polished'

import Heading from 'components/Heading'

export default ({ content, src, title, link, summerLink }) => (
  <Fragment>
    <Container image={src}>
      <Overlay>
        <Heading align="center">{title}</Heading>
        <Text>{content}</Text>
        {/*
            <ButtonContainer>
              <Text>Contact Our Office Today</Text>
               <MoreInfo href={link} target="_blank" rel="noopener noreferrer">
                YEAR ROUND PROGRAM
              </MoreInfo>
              <SummerInfo
                href={summerLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                SUMMER PROGRAM
              </SummerInfo>
            </ButtonContainer>
            */}
      </Overlay>
    </Container>
  </Fragment>
)

const Container = styled.div`
  width: 100%;
  height: 400px;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  transition-duration: 0.5s;

  @media (max-width: ${(props) => props.theme.desktop}px) {
    background-attachment: scroll;
  }
`
const Overlay = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(53, 170, 244, 0.75);
  padding: 70px 200px;

  @media (max-width: ${(props) => props.theme.desktop}px) {
    padding: 40px 20px;
  }
`
const Text = styled.p`
  margin: 20px 0;
  font-size: 16pt;
  line-height: 20pt;
  color: white;
  text-align: center;
  font-weight: 400;
`
// const ButtonContainer = styled.div`
//   width: 100%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   flex-wrap: wrap;
// `
// const MoreInfo = styled.a`
//   display: inline-block;
//   margin: 20px 15px 0;
//   padding: 30px 45px;
//   color: white;
//   background-color: ${props => props.theme.pink};
//   font-family: ${props => props.theme.bodyFont};
//   font-size: 14pt;
//   font-weight: 600;
//   line-height: 0;
//   cursor: pointer;
//   border: none;
//   border-radius: 30px;
//   outline: none;
//
//   &:hover {
//     background-color: ${props => darken(0.1, props.theme.pink)};
//     transition-duration: 0.8;
//   }
//
//   @media (max-width: 360px) {
//     font-size: 12pt;
//     padding: 25px 35px;
//     border-radius: 25px;
//   }
// `
// const SummerInfo = styled(MoreInfo)`
//   background-color: ${props => props.theme.purple};
//
//   &:hover {
//     background-color: ${props => darken(0.1, props.theme.purple)};
//     transition-duration: 0.8;
//   }
// `
