import styled from 'styled-components'

const InfoContainer = styled.div`
  padding: 60px 85px 65px;
  background-color: white;
  box-shadow: ${props => props.theme.boxShadow};

  @media (max-width: ${props => props.theme.desktop}px) {
    margin: 0;
    width: 100%;
  }

  @media (max-width: 587px) {
    padding: 60px 10px 65px;
  }
`
export default InfoContainer
