import React from 'react'
import styled from 'styled-components'

import Section from 'components/styled/Section'
import ImageDiv from 'components/styled/ImageDiv'
import InfoContainer from 'components/styled/InfoContainer'
import Heading from 'components/Heading'
import Markdown from 'components/styled/Markdown'

export default ({ content, title, src, color, reverse }) => (
  <MusicSection reverse={reverse}>
    <Image image={src} />
    <Info>
      <Heading color={color}>{title}</Heading>
      <Markdown color={color} children={content} />
    </Info>
  </MusicSection>
)

const MusicSection = styled(Section)`
  margin: 0;
  flex-direction: ${(props) => (props.reverse ? 'row-reverse' : 'row')};
`
const Image = styled(ImageDiv)`
  width: 50%;
`
const Info = styled(InfoContainer)`
  width: 50%;
  box-shadow: unset;
`
