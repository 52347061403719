import styled from 'styled-components'

const Section = styled.div`
  margin: 100px 0;
  height: auto;
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: ${props => props.theme.desktop}px) {
    margin: 0;
    padding: 0;
    display: block;
    width: 100%;
  }
`

export default Section
