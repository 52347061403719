import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import Seo from 'components/SEO'
import Header from './Header'
import MusicSplitScreen from './MusicSplitScreen'

export default ({ data }) => {
  const { frontmatter } = data.markdownRemark
  const {
    seo,
    header,
    privateLesson,
    groupLesson,
    orchestra,
    parentSupport,
    recitals,
    events,
  } = frontmatter
  return (
    <Fragment>
      <Seo title={seo.title} description={seo.description} />
      <Helmet title={seo.title} />
      <Header
        content={header.description}
        src={header.image.childImageSharp.gatsbyImageData.images.fallback.src}
        title={header.title}
      />
      <MusicSplitScreen
        content={privateLesson.description}
        title={privateLesson.title}
        src={
          privateLesson.image.childImageSharp.gatsbyImageData.images.fallback
            .src
        }
        color="purple"
      />
      <MusicSplitScreen
        content={groupLesson.description}
        title={groupLesson.title}
        src={
          groupLesson.image.childImageSharp.gatsbyImageData.images.fallback.src
        }
        color="lightBlue"
        reverse
      />
      <MusicSplitScreen
        content={orchestra.description}
        title={orchestra.title}
        src={
          orchestra.image.childImageSharp.gatsbyImageData.images.fallback.src
        }
        color="pink"
      />
      <MusicSplitScreen
        content={parentSupport.description}
        title={parentSupport.title}
        src={
          parentSupport.image.childImageSharp.gatsbyImageData.images.fallback
            .src
        }
        color="green"
        reverse
      />
      <MusicSplitScreen
        content={recitals.description}
        title={recitals.title}
        src={recitals.image.childImageSharp.gatsbyImageData.images.fallback.src}
        color="purple"
      />
      <MusicSplitScreen
        content={events.description}
        title={events.title}
        src={events.image.childImageSharp.gatsbyImageData.images.fallback.src}
        color="lightBlue"
        reverse
      />
    </Fragment>
  )
}

export const query = graphql`
  query MusicProgramPageQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        seo {
          title
          description
        }
        header {
          title
          description
          image {
            childImageSharp {
              gatsbyImageData(sizes: "2200")
            }
          }
        }
        privateLesson: private {
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
          title
          description
        }
        groupLesson: group {
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
          title
          description
        }
        orchestra {
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
          title
          description
        }
        parentSupport {
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
          title
          description
        }
        recitals {
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
          title
          description
        }
        events {
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
          title
          description
        }
      }
    }
  }
`
